<template>
  <div>
    <route-buttons />
    <b-tabs
      pills
      align="center"
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="PieChartIcon" />
          <span>{{ $t('Etki Analizi') }}</span>
        </template>
        <impact-analysis />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="EyeIcon" />
          <span>{{ $t('Rapor Özeti') }}</span>
        </template>
        <report-summary />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t('Rapor Detayı') }}</span>
        </template>
        <report-detail />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import routeButtons from '@/views/App/Reports/components/Route_buttons.vue'
import ReportDetail from '@/views/App/Reports/Sgd/ReportDetail.vue'
import ImpactAnalysis from '@/views/App/Reports/Sgd/ImpactAnalysis.vue'
import ReportSummary from '@/views/App/Reports/Sgd/ReportSummary.vue'

export default {
  name: 'SdgReport',
  components: {
    BTabs,
    BTab,
    ReportSummary,
    ImpactAnalysis,
    ReportDetail,
    routeButtons,
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('reportSdg/getData', this.$route.params.id)
    },
  },
}
</script>
