<template>
  <b-card no-body>
    <b-table
      :items="dataList"
      :fields="fields"
      responsive
    >
      <template #cell(sdg_group)="data">
        <div class="text-primary font-small-2 font-weight-bold">
          SDG {{ Number(data.item.id) }}
        </div>
        <div>SDG {{ data.item.sdg_group }}</div>
      </template>
      <template #cell(performance)="data">
        <div :class="data.item.performance >= 50? 'text-success' : 'text-danger'">
          %{{ data.item.performance | toNumber }}
        </div>
      </template>
      <template #cell(condition)="data">
        <div :class="data.item.performance >= 50? 'text-success' : 'text-danger'">
          {{ data.item.condition }}
        </div>
      </template>
      <template #cell(social_impact)="data">
        <div>
          {{ data.item.social_impact | toNumber }}
        </div>
      </template>
      <template #cell(social_performance)="data">
        <div :class="data.item.social_performance >= 50? 'text-success' : 'text-danger'">
          %{{ data.item.social_performance | toNumber }}
        </div>
      </template>
      <template #cell(sk_social)="data">
        <div
          v-if="data.item.sk_social !== null"
          :class="data.item.sk_social >= 50? 'text-success' : 'text-danger'"
        >
          %{{ data.item.sk_social | toNumber }}
        </div>
      </template>
      <template #cell(env_impact)="data">
        <div>
          {{ data.item.env_impact | toNumber }}
        </div>
      </template>
      <template #cell(env_performance)="data">
        <div :class="data.item.env_performance >= 50? 'text-success' : 'text-danger'">
          %{{ data.item.env_performance | toNumber }}
        </div>
      </template>
      <template #cell(sk_env)="data">
        <div
          v-if="data.item.sk_env !== null"
          :class="data.item.sk_env >= 50? 'text-success' : 'text-danger'"
        >
          %{{ data.item.sk_env | toNumber }}
        </div>
      </template>
      <template #cell(economic_impact)="data">
        <div>
          {{ data.item.economic_impact | toNumber }}
        </div>
      </template>
      <template #cell(economic_performance)="data">
        <div :class="data.item.economic_performance >= 50? 'text-success' : 'text-danger'">
          %{{ data.item.economic_performance | toNumber }}
        </div>
      </template>
      <template #cell(sk_economic)="data">
        <div
          v-if="data.item.sk_economic !== null"
          :class="data.item.sk_economic >= 50? 'text-success' : 'text-danger'"
        >
          %{{ data.item.sk_economic | toNumber }}
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'

export default {
  name: 'ReportSummary',
  components: {
    BCard,
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'sdg_group',
          label: 'SDG',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'performance',
          label: 'PERFORMANS',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'condition',
          label: 'KOŞUL',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'social_impact',
          label: 'SOSYAL ETKİ',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'social_performance',
          label: 'SOSYAL PERFORMANS',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'sk_social',
          label: 'SK SOSYAL',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'env_impact',
          label: 'ÇEVRESEL ETKİ',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'env_performance',
          label: 'ÇEVRESEL PERFORMANS',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'sk_env',
          label: 'SK ÇEVRESEL',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'economic_impact',
          label: 'EKONOMİK ETKİ',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'economic_performance',
          label: 'EKONOMİK PERFORMANS',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'sk_economic',
          label: 'SK EKONOMİK',
          thClass: 'text-nowrap text-center',
          tdClass: 'text-nowrap text-center',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['reportSdg/summary']
    },
  },
}
</script>
