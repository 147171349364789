<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-sm-0 mb-1">
        SGD Etki Analizi
      </b-card-title>
    </b-card-header>
    <b-card-body v-if="donutData.series.length">
      <vue-apex-charts
        type="pie"
        height="400"
        :options="donutData.chartOptions"
        :series="donutData.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

const chartColors = {
  donut: {
    series1: '#FF6384',
    series2: '#36A2EB',
    series3: '#FFCE56',
  },
}
export default {
  name: 'DonutChart',
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
  },
  computed: {
    donutData() {
      const data = this.$store.getters['reportSdg/donutData']
      return {
        series: data.series,
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series2,
            chartColors.donut.series3,
          ],
          dataLabels: {
            enabled: true,
            style: { // Burada data etiketleri için yazı stili ayarladık
              fontSize: '1.2rem', // Yazı büyüklüğü 16px olarak ayarlandı
              fontWeight: 'bold',
              fontFamily: 'Montserrat',
            },
            formatter(val, { seriesIndex, w }) {
              return w.globals.seriesTotals[seriesIndex]
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: data.labels[0],
                    formatter() {
                      return data.series[0]
                    },
                  },
                },
              },
            },
          },
          labels: data.labels,
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      }
    },
  },
}
</script>
